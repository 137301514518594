import Layout from "components/layout"
import Link from "next/link"
import S from "./404.module.scss"
import BackIcon from "../svgs/back.svg"

export default function Custom404() {
  return (
    <Layout title="404">
      <section className="text-center u-section-padding">
        <img
          src="/images/404.svg"
          alt="404 - Page Not Found"
          className="mb-2 mb-md-3"
        />

        <p className={S.title}>Oooops!</p>

        <div className="content wysiwyg mt-3 mb-4 mb-md-5 px-5">
          We can’t seem to find the page you’re looking for.
        </div>

        <Link href="/" className="btn btn-outline-dark">
          <BackIcon />
          Back to Homepage
        </Link>
      </section>
    </Layout>
  )
}
